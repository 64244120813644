<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
		 :handleSearch="true" @onSearch="onSearch" @clearSearch="clearSearch" @getList="getList">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item" >
				<el-select
						v-model="searchFormData.group_id"
						filterable
						reserve-keyword
						clearable
						placeholder="请选择小区"
						v-if="$hasAccess('device.group/view')"
						:remote-method="remoteGroup"
						:loading="groupLoading">
					<el-option
							v-for="item in groupList"
							:key="item.group_id"
							:label="item.group_name"
							:value="item.group_id">
					</el-option>
				</el-select>
				<el-form-item>
					<el-date-picker class="from-item-width" unlink-panels v-model="dates" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_name" title="小区名称" align="center" />
			<vxe-table-column slot="table-item" field="all_order_short_money" title="消费金额" align="center" />
			<vxe-table-column slot="table-item" field="all_recharge_wx_money" title="充值金额" align="center" />
			<vxe-table-column slot="table-item" field="all_recharge_card_money" title="卡充值金额" align="center" />
			<vxe-table-column slot="table-item" field="cash_money" title="现金收入" align="center" />
			<vxe-table-column slot="table-item" field="all_recharge_refund_money" title="充值退费金额" align="center" />
			<vxe-table-column slot="table-item" field="all_order_refund_money" title="现金退费金额" align="center" />
			<vxe-table-column slot="table-item" field="all_order_money" title="累计总金额" align="center" />
		</Tables>
	</div>
</template>

<script>
	import Tables from "@/components/tables";

	export default {
		name: "report-group-list",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "小区消费查询",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				groupList: [],
				searchFormData: {},
				dates: [],
				groupLoading: false,
				shareStatus: {
					10: {
						txt: '不结算',
						class: 'sd_spare2'
					},
					20: {
						txt: '未结算',
						class: 'sd_error',
					},
					30: {
						txt: '已结算',
						class: 'sd_spare'
					}
				}
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
		},
		methods: {
			// 初始化请求
			init() {
				this.getGroupList();
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000
				}
				const res = await this.$api.Device.GetGroupList(params, {})
				this.groupList = res.data
			},
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				if (this.searchFormData.group_id) {
					this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
					this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
					const params = {
						token: this.$store.state.user.token,
						page: currentPage,
						size: pageSize,
						form: JSON.stringify(this.searchFormData)
					};
					this.isLoading = true;
					const res = await this.$api.Report.GetGroupViewA(params);
					this.tableData = res; // res.data;
					this.totalPage = res.length;
					this.isLoading = false;
				} else {
					this.tableData = [];
					this.totalPage = 0;
				}
			},
			// 查询
			onSearch() {
				if (!this.searchFormData.group_id) {
					if (!this.groupList.length){
						this.searchFormData.group_id=99999999999999999999999999999999999;
					} else {
						this.searchFormData.group_id=99999;
						// this.$message.warning('请选择小区')
						// return;
					}
				}
				if (!this.dates || this.dates.length != 2) {
					this.$message.warning('请选择要查询的时间段')
					return;
				}
				this.getList();
			},
			// 重置查询
			clearSearch() {
				this.dates = [];
				this.searchFormData = {};
			}
		},
	};
</script>

<style lang="less" scoped>
	.from-item-width {
		width: 240px;
	}

	.from-item-split {
		margin-right: 20px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
